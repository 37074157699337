
import {
  IonContent,
  IonCol,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/vue'
import { ellipsisVertical, addCircle } from 'ionicons/icons'
import Header from '@/components/header/AppHeader.vue'
import FeedPost from '@/components/homepage/DailyFeedPost.vue'

export default {
  name: 'UserList',
  components: {
    IonContent,
    IonPage,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Header,
    FeedPost,
  },
  setup() {
    return {
      ellipsisVertical,
      addCircle,
    }
  },
  data() {
    return {
      testPosts: [
        {
          id: 1,
          authorName: 'Me',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 08:32:07 GMT+0200',
          social: {
            likes: 158,
            shares: 63,
            comments: 15,
          },
        },
        {
          id: 2,
          authorName: 'Me',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 08:38:09 GMT+0200',
          social: {
            likes: 15,
            shares: 6,
            comments: 5,
          },
        },
        {
          id: 3,
          authorName: 'Me',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 09:32:07 GMT+0200',
          social: {
            likes: 60,
            shares: 18,
            comments: 8,
          },
        },
        {
          id: 4,
          authorName: 'Me',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 11:36:12 GMT+0200',
          social: {
            likes: 13,
            shares: 2,
            comments: 0,
          },
        },
      ],
    }
  },
}
